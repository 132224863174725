import React from "react";
// import "./ShimmerBlogCard.css"; // Import the CSS file

const ShimmerBlogCard = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer-card flex flex-col gap-4 w-80 h-full rounded-xl">
        <div className="shimmer-bg w-full h-40 rounded-t-xl"></div>

        <div className="pt-0 text-start flex flex-col gap-2 rounded-xl">
          <div className="shimmer-bg w-full h-4"></div>

          <div className="flex justify-between flex-wrap mb-2 pt-0">
            <span className="shimmer-bg w-10 h-2 rounded-xl"></span>
            <span className="shimmer-bg w-10 h-2 rounded-xl"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerBlogCard;
