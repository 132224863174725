import React from 'react'
import ShimmerBlogCard from './ShimmerBlogCard'

const ShimmerBlogSection = () => {
  return (
    <div className="flex lg:flex-row flex-col gap-6 flex-wrap justify-start items-center">
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
      <ShimmerBlogCard />
    </div>
  );
}

export default ShimmerBlogSection